<template>
  <div class="container ml-blog-category mb-5">
    <div class="row align-items-center  no-gutters">
      <div class="col-sm-7 col-lg-8">
        <div class="breadcrumd-custom">
          <nuxt-link :to="isBlogApp ? '/blog-app' : '/blog'">Blog</nuxt-link>
          <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" />
          <span class="active">{{category.name}}</span>
        </div>
      </div>
      <div class="col-sm-5 col-lg-4">
        <FormSearch :value="keyword" @enter="searchData" placeholder="Tìm bài viết"/>
      </div>
      <div class="col-12 category-title font-weight-bold">{{category.name}}</div>
    </div>
    <div class="blog-list pt-3">
      <BlogItem v-for="item in listData" :key="item.id" :item="item"/>
      <div v-if="listData.length < query.total" class="load-more text-center">
        <button @click="loadData" class="font-weight-bold text-center d-flex align-items-center bg-white ml-auto border-0">Xem thêm</button>
      </div>
    </div>
  </div>
</template>
<script>
import FormSearch from '~/components/mlearn/select/form-search.vue'
import BlogItem from '~/components/mlearn/blog/item.vue'
import Resource from '~/common/api/resource'
const categoryBySlugResource = new Resource('blog/category/getBySlug')
const blogArticleResource = new Resource('blog/article')
export default {
  components: {
    BlogItem,
    FormSearch
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    const category = await categoryBySlugResource.list({ slug: params.slug })
      .catch(() => {
        return error({ statusCode: 404, title: 'Danh mục không tồn tại', message: 'Danh mục đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!category) {
      return error({ statusCode: 404, title: 'Danh mục không tồn tại', message: 'Danh mục đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const query = { limit: 6, offset: 0, total: 0, category: category.data.id }
    const { data, total } = await blogArticleResource.list(query)
    query.total = total
    query.offset += data.length
    const listData = []
    listData.push(...data)
    return {
      category: category.data,
      listData,
      query
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  computed: {
    isBlogApp () {
      return /blog-app/.test(this.$route.name)
    }
  },
  methods: {
    searchData (keyword) {
      this.keyword = keyword
      if (!this.keyword) { return }
      this.$router.push((this.isBlogApp ? '/blog-app' : '/blog') + '/search?q=' + encodeURIComponent(this.keyword))
    },
    async loadData () {
      const { data, total } = await blogArticleResource.list(this.query)
      this.query.total = total
      this.query.offset += data.length
      this.listData.push(...data)
    }
  }
}
</script>

<style lang="scss">
.ml-blog-category{
  .category-title{
    font-size: 24px;
  }
  .blog-list{
    .blog-item{
      margin-top: 10px;
      padding-bottom: 20px;
      border-bottom:1px solid #E2E2E2;
      &:first-child{
        margin-top: 0px;
      }
      &:last-child{
        margin-bottom: 40px;
        border-bottom: 0;
      }
    }
    .load-more{
      margin: 40px auto;
      button{
        box-shadow: 0px 4px 7px rgba(156, 156, 156, 0.38);
        border-radius: 22px;
        padding: 11px 28px 11px 28px;
        font-size: 16px;
        color: #004390;
      }
    }
  }
}
@media (min-width: 992px){
  .ml-blog-category{
    .category-title{
      font-size: 26px;
    }
    .blog-list{
      .blog-item{
        margin-top: 10px;
        padding-bottom: 20px;
        border-bottom:1px solid #E2E2E2;
      }
      .load-more{
        font-size: 16px;
      }
    }
  }
}
@media (min-width: 1366px){
  .ml-blog-category{
    .blog-list{
      .blog-item{
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}
</style>

<template>
  <div class="ml-quiz mb-5">
    <div class="container">
      <div class="row">
<!--        <div class="col-lg-2 offset-lg-1 col-xl-2 offset-xl-0 d-none d-lg-block">-->
<!--          <MenuLeft @selectClass="findClass" :selected-id="query.class_id"/>-->
<!--        </div>-->
        <div class="col-lg-10 col-xl-9 col-xl-12">
          <div class="filter-quiz d-flex justify-content-start">
            <CategorySelect class="d-none d-lg-block mr-2" placeholder="Môn" @findCategory="findCategory" :selected-id="query.category_id" :library-show="true"/>
            <ExampTypeList class="d-none" @findExamType="findExamType" placeholder="Loại đề"/>
            <ExampTimeList class="d-none" @findExamTime="findExamTime" placeholder="Thời gian"/>
            <ExampGroupList class="d-none" @findExamGroup="findExamGroup" placeholder="Khóa học"/>
            <FormSearch class="ml-auto" :value="query.keyword" @enter="handerSearch" />
          </div>
          <div v-for="(topic, ind) in topics" :key="ind" >
            <div class="hot-topic mt-3" v-if="!keyword">
              <div class="title d-flex align-items-center">{{ topic.name }} <nuxt-link v-if="topic.exams.length" :to="'/bo-de/khoa-hoc/' + topic.slug" class="view-more ml-auto pointer">Xem thêm</nuxt-link></div>
              <VueSlickCarousel v-if="topic.exams.length" v-bind="carouselSettings">
                <QuizItem v-for="item in topic.exams" :key="item.id" :item="item" :action="false"/>
                <template #prevArrow>
                  <ArrowLeft />
                </template>
                <template #nextArrow>
                  <ArrowRight />
                </template>
              </VueSlickCarousel>
              <NoData v-if="!topic.exams.length && !loading" text="Nội dung đang cập nhật" class="py-3"/>
            </div>
          </div>
          <div class="hot-topic mt-3" v-if="listData.length && !keyword">
            <div class="title d-flex align-items-center">Bộ đề của tôi <span class="view-more ml-auto pointer">Xem thêm</span></div>
            <VueSlickCarousel v-bind="carouselSettings">
              <QuizItem v-for="item in listData" :key="item.id" :item="item" :action="false"/>
              <template #prevArrow>
                <ArrowLeft />
              </template>
              <template #nextArrow>
                <ArrowRight />
              </template>
            </VueSlickCarousel>
          </div>
          <template v-if="keyword">
            <div v-if="keyword" class="text-primary text-break mt-3">
              {{ query.total }} kết quả tìm kiếm cho "<b>{{ keyword }}</b>"
            </div>
            <div class="form-content mt-3">
              <ViewMode title="Bộ đề" @mode="changeMode" />
              <div class="row list-item">
                <QuizItem v-for="(item) in listData" :key="item.id" :class="viewList ? 'col-sm-6 col-md-4 col-xl-3' : 'col-12'" :item="item" :view-list="viewList" :playlist="playlist" @createdPlaylist="loadPlayList()" @showNotify="showNotify"/>
              </div>
              <NoData v-if="!listData.length && !loading" text="Nội dung đang cập nhật"/>
              <Pagination :total="query.total" :value.sync="query.page" @change="loadData" :limit="query.limit" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cookieParser from 'cookieparser'
// import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import { EventBus } from '~/common/utils/eventBus'
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import CategorySelect from '~/components/mlearn/select/category.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Pagination from '~/components/mlearn/pagination/index.vue'
import ExampTypeList from '~/components/mlearn/select/examType.vue'
import ExampTimeList from '~/components/mlearn/select/examTime.vue'
import ExampGroupList from '~/components/mlearn/select/examGroup.vue'
import QuizItem from '~/components/mlearn/quiz/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'
const metaResource = new Resource('config/seo')
const topicResource = new Resource('library/groupExam')
const examResource = new Resource('library/exam')
const playlistResource = new Resource('library/playlist')
export default {
  components: {
    // MenuLeft,
    ViewMode,
    CategorySelect,
    FormSearch,
    Pagination,
    ExampTypeList,
    ExampTimeList,
    ExampGroupList,
    QuizItem,
    PlaylistNotify
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    let token = ''
    if (req && req?.headers && req?.headers?.cookie) {
      const requestCookies = cookieParser.parse(req?.headers?.cookie)
      token = requestCookies.access_token || ''
    }
    const query = {
      category_id: '',
      class_id: '',
      exam_type_id: '',
      keyword: '',
      page: 1,
      limit: 20,
      total: 0
    }
    const queryTopic = {
      category_id: '',
      class_id: '',
      page: 1,
      limit: 12,
      limitItem: 12,
      total: 0
    }
    const storeData = store.state.storeRedirect
    if (storeData) {
      query.category_id = storeData.category_id || ''
      query.class_id = storeData.class_id || ''
      queryTopic.category_id = storeData.category_id || ''
      queryTopic.class_id = storeData.class_id || ''
    }
    const data = await topicResource.list(queryTopic, token)
    const topics = data.data
    const listData = []
    query.total = data.total || 0
    const playlist = []
    const meta = await metaResource.list({ path: route.path })
    const metaSeo = meta.data
    const structuredData = {}
    return {
      topics,
      listData,
      query,
      queryTopic,
      playlist,
      loading: false,
      flagNotify: false,
      textNotify: '',
      keyword: '',
      metaSeo,
      structuredData,
      viewList: true
    }
  },
  data () {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.2
            }
          }
        ]
      }
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  created () {
    EventBus.$on('handleChangeClassMenu', this.handleChangeClassMenu)
    this.loadPlayList()
    this.$store.dispatch('setStoreRedirect', null)
  },
  destroyed () {
    EventBus.$off('handleChangeClassMenu', this.handleChangeClassMenu)
  },
  mounted () {
    if (localStorage.getItem('quiz')) {
      this.showNotify(`Đã lưu bài làm <b>${localStorage.getItem('quiz')}</b> vào Thư viện của tôi`)
      localStorage.removeItem('quiz')
    }
  },
  methods: {
    async loadTopic () {
      const data = await topicResource.list(this.queryTopic)
      this.topics = data.data
    },
    changeMode (mode) {
      this.viewList = mode
    },
    findClass (classId) {
      this.query.class_id = classId
      this.queryTopic.class_id = classId
      this.query.total = 0
      this.loadData()
    },
    findCategory (categoryId) {
      this.query.category_id = categoryId
      this.queryTopic.category_id = categoryId
      this.query.total = 0
      this.loadData()
    },
    findExamType (examType) {
      this.query.exam_type_id = examType
      this.queryTopic.exam_type_id = examType
      this.query.total = 0
      this.loadData()
    },
    findExamTime (value) {
      this.query.time = value
      this.queryTopic.time = value
      this.query.total = 0
      this.loadData()
    },
    findExamGroup (examGroup) {
      this.query.set_exam_id = examGroup
      this.queryTopic.set_exam_id = examGroup
      this.query.total = 0
      this.loadData()
    },
    async loadData (page = 1) {
      this.query.page = page
      if (!this.keyword) {
        this.loadTopic()
        return
      }
      if (this.loading) { return }
      this.loading = true
      const data = await examResource.list(this.query)
      this.listData = data.data || []
      this.query.total = data.total || 0
      this.keyword = this.query.keyword
      this.loading = false
    },
    handerSearch (keyword) {
      this.query.total = 0
      this.keyword = keyword
      this.query.keyword = keyword
      this.loadData()
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list({ type: 'exam' })
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    handleChangeClassMenu (obj) {
      this.findClass(obj.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-quiz{
    .title{
      font-weight: bold;
      font-size: 24px;
      @media (max-width:1366px) {
        font-size: 18px;
      }
      @media (max-width:576px) {
        font-size: 16px;
      }
    }
    .view-more{
      font-weight: 500;
      color: #65676B;
      font-size: 16px;
      @media (max-width:1366px) {
        font-size: 15px;
      }
      @media (max-width:576px) {
        font-size: 14px;
      }
    }
    .hot-topic {
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 12px;
      padding: 20px 20px 10px 20px;
      @media (max-width:1366px) {
        padding: 18px;
      }
      @media (max-width:576px) {
        padding: 16px;
      }
    }
    .form-content{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 12px;
      padding:20px;
      @media (max-width:1366px) {
        padding:18px;
      }
      @media (max-width:576px) {
        padding:16px;
      }
      .list-item{
        > [class*="col-"] {
          padding-top: 20px;
          @media (max-width:1366px) {
            padding-top: 18px;
          }
          @media (max-width:576px) {
            padding-top: 16px;
          }
          &.col-12{
            padding-top: 0;
            border-bottom: 1px solid #EBEBEB;
            &:last-child{
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.ml-quiz {
  .hot-topic {
    .slick-slider{
      .slick-list{
        margin: 0 -9px;
        .slick-track{
          .slick-slide{
            padding: 10px 9px;
            @media (max-width:1366px) {
              padding: 10px 5px;
            }
            @media (max-width:576px) {
              padding: 10px 5px;
            }
          }
        }
      }
    }
  }
}
</style>

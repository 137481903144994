<template>
  <Guide/>
</template>

<script>
import Guide from '~/components/mlearn/quiz/guide.vue'
export default {
  components: {
    Guide
  },
  layout: 'event'
}
</script>

<template>
  <div class="bg-white min-vh-100"></div>
</template>
<script>
import Resource from '~/common/api/resource'
const activeAccountResource = new Resource('user/activeAccount')
export default {
  layout: 'empty',
  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    if (store.state.user || !query.token) {
      return redirect('/')
    }
  },
  created () {
    const data = { token: this.$route.query.token }
    activeAccountResource.store(data)
      .then((res) => {
        if (res.status === 200) {
          this.$notify({
            type: 'success',
            message: 'Đã xác thực tài khoản thành công',
            duration: 0
          })
        } else {
          this.$notify({
            type: 'warning',
            message: res.message,
            duration: 0
          })
        }
      })
      .catch((err) => {
        console.log(err)
        this.$notify({
          type: 'warning',
          message: 'Xác thực tài khoản chưa thành công',
          duration: 0
        })
      })
      .finally(() => {
        this.$router.push('/')
      })
  }
}
</script>

<style lang="scss" scoped>

</style>
